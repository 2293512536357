import { CorrespondentieVoorkeur } from '@/client/types'
import { useI18n } from 'phoenix'

export default function () {
  const { t } = useI18n()

  return [
    {
      label: t('correspondence.receiveByEmail'),
      value: CorrespondentieVoorkeur.Digitaal,
      id: 'input-choice-email',
    },
    {
      label: t('correspondence.receiveByregularMail'),
      value: CorrespondentieVoorkeur.Papier,
      id: 'input-choice-regular-mail',
    },
  ]
}
