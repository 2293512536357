





























































import IdentityCard from '@/components/IdentityCard.vue'
import { defineComponent, ref } from '@vue/composition-api'
import i18n from './i18n'
import { createValidation } from '@/composables/createValidation'
import { loginTestUser } from '@/client/endpoints'
import usePageCookie from '@/composables/usePageCookie'
import { useSubmitForm } from '@/composables/useSubmitForm'

export default defineComponent({
  components: { IdentityCard },
  name: 'LoginTestUser',
  i18n,
  setup () {
    const username = ref('')
    const password = ref('')
    const usernameValidation = createValidation({ field: username, required: true, emptyErrorMessage: 'notEmpty' })
    const passwordValidation = createValidation({ field: password, required: true, emptyErrorMessage: 'notEmpty' })
    const pageCookie = usePageCookie()
    const testUserLogin = useSubmitForm(
      () => {
        pageCookie.set()
        return loginTestUser({
          username: username.value,
          password: password.value,
          returnUrl: sessionStorage.getItem('returnUrl'),
        })
      }
    )

    return {
      usernameValidation,
      passwordValidation,
      username,
      password,
      ...testUserLogin,
    }
  },
})

