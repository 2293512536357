import '@/plugins/composition-api'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import registerPhoenix from '@/plugins/phoenix'
import checkIfAppShouldRedirect from '@/utils/checkIfAppShouldRedirect'

Vue.config.productionTip = false
const { vuetify, i18n } = registerPhoenix(router)

function bootstrap () {
  checkIfAppShouldRedirect(router).then((ready) => {
    if (ready) {
      new Vue({
        // @ts-ignore
        router,
        vuetify,
        i18n,
        render: h => h(App),
      }).$mount('#app')
    }
  })
}

bootstrap()
