export default {
  messages: {
    nl: {
      aria: {
        svbLogin: 'Inloggen met SVB als {role}',
        vecozoLogin: 'Inloggen met VECOZO als {role}',
        gemnetLogin: 'Inloggen met SesamID als {role}',
        eherkenningLogin: 'Inloggen met eHerkenning als {role}',
      },
      employees: {
        roles: {
          svbMedewerker: 'SVB medewerker',
          zorgkantoorMedewerker: 'zorgkantoor medewerker',
          gemeenteMedewerker: 'gemeente medewerker',
        },
        company: {
          svb: {
            cardHeader: 'SVB',
            description: 'SVB medewerkers kunnen inloggen met SVB.',
            login: 'Inloggen SVB',
          },
          vecozo: {
            cardHeader: 'Zorgkantoren',
            description: 'Voor medewerkers van zorgkantoren.',
            login: 'Inloggen Vecozo',
          },
          gemnet: {
            cardHeader: 'Gemeente - SesamID',
            description: 'Gemeente medewerkers kunnen inloggen met SesamID.',
            login: 'Inloggen SesamID',
          },
          eHerkenning: {
            cardHeader: 'Gemeente - eHerkenning',
            description: 'Gemeente medewerkers kunnen inloggen met eHerkenning.',
            login: 'Inloggen eHerkenning',
          },
        },
      },
      adminLink: 'Bent u een functioneel applicatie beheerder?',
      budgethouderLink: 'Bent u een budgethouder, vertegenwoordiger of zorgverlener?',
      inactiveMessages: {
        vecozo: 'VECOZO heeft momenteel te maken met een storing. Inloggen op het PGB Portaal is daarom nu niet mogelijk.',
        svb: 'Het is op dit moment niet mogelijk om gebruik te maken van SVB om in te loggen op het PGB Portaal.',
        gemnet: 'Het is op dit moment niet mogelijk om gebruik te maken van Gemnet om in te loggen op het PGB Portaal.',
        eHerkenning: 'eHerkenning heeft momenteel te maken met een storing. Inloggen op het PGB Portaal is daarom nu niet mogelijk.',
      },
      errorMessages: {
        notEmpty: 'Dit veld mag niet leeg zijn',
        authError: 'De combinatie van gebruikersnaam en wachtwoord is onjuist. Probeer het nog eens',
        unknownError: 'Er heeft zich een onbekende fout voorgedaan',
      },
    },
  },
}
