import { cookie } from '@/utils/cookie'
import { getCurrentInstance } from '@vue/composition-api'
import { getChildRoutes } from '@/utils/getChildRoutes'
import { RouteConfigSingleView } from 'vue-router/types/router'
import { ComponentInstance } from '@vue/composition-api/dist/component'
import { serverLoginUrl } from '@/utils/identifyPages'

export class PageCookie {
  set (customRouteName: string) {
    cookie.set(PageCookie.cookieKey, customRouteName)
  }

  delete () {
    cookie.delete(PageCookie.cookieKey)
  }

  value (): string {
    return cookie.get(PageCookie.cookieKey)
  }

  static get cookieKey () {
    return 'loginPagePreference'
  }
}

export class PageCookieComposable extends PageCookie {
  vm: ComponentInstance
  constructor () {
    super()
    this.vm = getCurrentInstance() as ComponentInstance
    this.set = this.set.bind(this)
  }

  set () {
    cookie.set(PageCookie.cookieKey, (this.vm as any).$route.name)
  }

  getCorrespondingPageRoute (): RouteConfigSingleView | undefined {
    if (!this.value()) {
      return
    }
    const loginRoutes = getChildRoutes((this.vm as any).$router, { path: serverLoginUrl })
    const correspondingRoute = loginRoutes.find(route => route.name === this.value())
    if (correspondingRoute) {
      return correspondingRoute
    } else {
      this.delete()
    }
  }
}

export default function () {
  return new PageCookieComposable()
}
