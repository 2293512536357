






















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'IdentityCard',
  props: {
    title: { type: String, required: true },
    headingLevel: { type: [String, Number], required: true },
    img: { type: String, default: '' },
    imgAlt: { type: String, default: '' },
    inactive: { type: String, default: '' },
    error: { type: String, default: '' },
    isNamedSection: { type: Boolean, default: false },
    mediumColumnClass: { type: [Boolean, String, Number], default: 4 },
  },
})
