









import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { logout } from '@/client/endpoints'
import { redirect, search } from '@/utils/urlMethods'

export default defineComponent({
  name: 'Logout',

  setup () {
    const done = ref(false)
    // Using the vue-router as well as window.location results in difficult testing scenario's
    // So window.location is being used only to extract the data

    const logoutIframe = ref(null)
    const query = new URLSearchParams(search())

    const handleLogout = async () => {
      const data = await logout(query.get('logoutId'))

      if (data.signOutIFrameUrl) {
        const iframe = document.createElement('iframe')
        iframe.width = '0'
        iframe.height = '0'
        // @ts-ignore
        iframe.class = 'signout'
        iframe.src = data.signOutIFrameUrl
        // @ts-ignore
        logoutIframe.value.appendChild(iframe)
      }

      if (data.postLogoutRedirectUri) {
        redirect(data.postLogoutRedirectUri)
      } else {
        done.value = true
      }
    }
    onMounted(handleLogout)
    return {
      done,
      logoutIframe,
      handleLogout,
    }
  },
})
