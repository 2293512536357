import { createValidation } from '@/composables/createValidation'
import { EMAIL } from '@/utils/regex'
import { reactive, ref } from '@vue/composition-api'
import { Ref, UnwrapRef } from '@vue/composition-api/dist/reactivity'

export function createEmailField ({ required }: {required?: Ref<boolean> | boolean}) {
  const model = ref('')
  const validation = createValidation({
    field: model,
    required,
    emptyErrorMessage: 'notEmptyEmail',
    customValidation: [
      {
        validate: () => !model.value || EMAIL.test(model.value),
        messageId: 'invalidEmail',
      },
    ],
  })
  return reactive({ model, validation })
}

export function createEmailConfirmField ({ emailField, required }: {emailField: UnwrapRef<{model: string}>, required?: Ref<boolean> | boolean}) {
  const model = ref('')
  const validation = createValidation({
    field: model,
    required,
    emptyErrorMessage: 'notEmptyEmail',
    customValidation: [
      {
        validate: () => emailField.model === model.value,
        messageId: 'confirmEmail',
      }],
  })
  return reactive({ model, validation })
}

export function createTermsAndConditionsField () {
  const model = ref(false)
  const validation = createValidation({
    customValidation: [
      { validate: () => model.value, messageId: 'terms' }],
    required: true,
  })
  return reactive({ validation, model })
}

export function createTextField ({ required, length, messageId }: {required?: Ref<boolean> | boolean, requiredMessageId?: string, length: number, messageId: string}) {
  const model = ref('')
  const validation = createValidation({
    field: model,
    required,
    customValidation: [{ validate: () => model.value.length <= length, messageId }],
    emptyErrorMessage: 'notEmptyField',
  })
  return reactive({ model, validation })
}
