import { validateForm } from '@/composables/createValidation'
import { redirect } from '@/utils/urlMethods'
import { getCurrentInstance, ref, Ref } from '@vue/composition-api'
import { useI18n } from 'phoenix'

interface LoginAssets {
  formRef: Ref<any>,
  processing: Ref<boolean>,
  serverError: Ref<string>,
  t: Function
}

export interface DefaultResponse {
  isOk: boolean
  redirectUrl?: string | null
  status?: number | null
}

interface UseLoginComposable extends Omit<LoginAssets, 't'> {
  submit: () => Promise<void>
}

export function submitForm<T extends DefaultResponse>
(assets: LoginAssets, apiCall: () => Promise<T>): () => Promise<void> {
  return async () => {
    const { formRef, processing, serverError, t } = assets
    const valid = await validateForm(formRef)
    if (valid) {
      processing.value = true
      try {
        const resp = await apiCall()
        if (resp && resp.isOk && resp.redirectUrl) {
          redirect(resp.redirectUrl)
        } else {
          throw new Error('Invalid response')
        }
      } catch (err) {
        const error = err as DefaultResponse
        if (error.redirectUrl) {
          redirect(error.redirectUrl)
        } else {
          processing.value = false
          serverError.value = t(`errorMessages.${error.status === 401 ? 'authError' : 'unknownError'}`)
        }
      }
    }
  }
}

export function useSubmitForm<T extends DefaultResponse> (apiCall: () => Promise<T>): UseLoginComposable {
  const vm = getCurrentInstance() as any
  const formRef = ref<any>(null)
  const processing = ref(false)
  const serverError = ref('')
  const { t } = useI18n(vm)
  return {
    submit: submitForm({ formRef, processing, serverError, t }, apiCall),
    formRef,
    processing,
    serverError,
  }
}
