





















import { useRouter } from 'phoenix'
import { defineComponent } from '@vue/composition-api'
import usePageCookie from '@/composables/usePageCookie'
import { getFunctionalError } from '@/utils/getFunctionalError'
import { useStoreAndHideQueryParams } from '@/composables/useStoreAndHideQueryParams'
import i18n from './i18n'
import { isServerLoginUrl } from '@/utils/identifyPages'

export default defineComponent({
  name: 'Login',
  i18n,
  setup () {
    const { route, router } = useRouter()
    const pageCookie = usePageCookie()
    const correspondingPageRoute = pageCookie.getCorrespondingPageRoute()

    const queries = useStoreAndHideQueryParams({ store: [{ queryKey: 'ReturnUrl', storageKey: 'returnUrl' }] })

    if (correspondingPageRoute && isServerLoginUrl(route.path) && correspondingPageRoute.name !== 'LoginMain') {
      router.replace({ name: correspondingPageRoute.name })
    }

    return { errorMessage: getFunctionalError(queries.validationMessage) || '' }
  },
})
