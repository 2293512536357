

























































import IdentityCard from '@/components/IdentityCard.vue'
import usePageCookie from '@/composables/usePageCookie'
import { defineComponent } from '@vue/composition-api'
import i18n from './i18n'
import { useProviderSettings } from '@/composables/useProviderSettings'

export default defineComponent({
  components: { IdentityCard },
  name: 'LoginAdmin',
  i18n,
  setup () {
    return {
      providers: useProviderSettings().providers,
      setPageCookie: usePageCookie().set,
    }
  },
})

