import { fetchProviderSettings } from '@/client/endpoints'
import { ref } from '@vue/composition-api'

export interface IProviders {
  [key: string]: {
    authenticationScheme: string,
    url: string,
    enabled: boolean
  }
}
const knownProviders: IProviders = {
  eHerkenningVertegenwoordiger: { authenticationScheme: 'eherkenningvertegenwoordiger', url: '', enabled: false },
  eHerkenningZorgverlener: { authenticationScheme: 'eherkenningzorgverlener', url: '', enabled: false },
  eHerkenningGemeente: { authenticationScheme: 'eherkenninggemeente', url: '', enabled: false },
  digidVertegenwoordiger: { authenticationScheme: 'digidvertegenwoordiger', url: '', enabled: false },
  digidZorgverlener: { authenticationScheme: 'digidzorgverlener', url: '', enabled: false },
  digidBudgethouder: { authenticationScheme: 'digidbudgethouder', url: '', enabled: false },
  svb: { authenticationScheme: 'svb', url: '', enabled: false },
  vecozo: { authenticationScheme: 'vecozo', url: '', enabled: false },
  gemnet: { authenticationScheme: 'gemnet', url: '', enabled: false },
  fabber: { authenticationScheme: 'fabber', url: '', enabled: false },
}

const providers = ref<any>(null)

async function getProviderSettings () {
  const returnUrl = sessionStorage.getItem('returnUrl') || ''
  const result = await fetchProviderSettings()
  Object.values(knownProviders).forEach((provider) => {
    const providerSetting = result.providers.find((p: any) => p.authenticationScheme === provider.authenticationScheme)
    if (providerSetting) {
      provider.enabled = true
      provider.url = `${providerSetting.url}&returnUrl=${encodeURIComponent(returnUrl)}`
    }
  })
  providers.value = knownProviders
}

export function useProviderSettings () {
  if (!providers.value) {
    getProviderSettings()
  }
  return { providers }
}
