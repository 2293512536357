export default {
  messages: {
    nl: {
      aria: {
        digidLogin: 'Inloggen met DigiD als {role}',
        vecozoLogin: 'Inloggen met VECOZO als {role}',
        eherkenningLogin: 'Inloggen met eHerkenning als {role}',
      },
      login: {
        login: 'Inloggen',
        employeesLink: 'Bent u werkzaam voor een verstrekker of de SVB?',
        providers: {
          digid: {
            cardHeader: 'Inloggen met DigiD',
            description: 'Log in als:',
            notEnabled: 'DigiD is niet ingeschakeld',
          },
          vecozo: {
            cardHeader: 'Inloggen met VECOZO',
            description: 'Log in als zzp\'er of zorginstelling:',
            notEnabled: 'Vecozo is niet ingeschakeld',
          },
          eHerkenning: {
            cardHeader: 'Inloggen met eHerkenning',
            description: 'Log in namens een organisatie als:',
            notEnabled: 'eHerkenning is niet ingeschakeld',
          },
        },
        roles: {
          budgethouder: 'Budgethouder',
          vertegenwoordiger: 'Vertegenwoordiger',
          informeleZorgverlener: 'Informele zorgverlener',
          formeleZorgverlener: 'Formele zorgverlener',
          zorgverlener: 'Zorgverlener',
        },
        inactiveMessages: {
          digid: 'DigiD heeft te maken met een storing. Inloggen op het PGB Portaal is daarom nu niet mogelijk.',
          vecozo: 'VECOZO heeft momenteel te maken met een storing. Inloggen op het PGB Portaal is daarom nu niet mogelijk.',
          eHerkenning: 'eHerkenning heeft momenteel te maken met een storing. Inloggen op het PGB Portaal is daarom nu niet mogelijk.',
        },
        errorMessages: {
          general: {
            incorrectCredentials: 'Inloggen lukt niet, omdat uw gegevens niet bekend zijn binnen het PGB Portaal (PGB2.0).' +
            'Neem hierover contact op met uw verstrekker. Regelt u uw pgb-zaken in Mijn PGB van de SVB?' +
            'Ga dan naar {link}',
            maintenance: 'We voeren momenteel onderhoud uit. Daarom kunt u op dit moment niet inloggen. Probeer het over enkele uren nogmaals',
          },
        },
      },
      help: {
        loginHelp: 'Als wie moet ik inloggen?',
        moreInfoDigid: 'Meer informatie over DigiD',
        moreInfoVecozo: 'Meer informatie over VECOZO',
        moreInfoEherkenning: 'Meer informatie over eHerkenning',
        faq: 'Veelgestelde vragen',
      },
    },
  },
}
