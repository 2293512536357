import { useRouter } from 'phoenix'

export const useStoreAndHideQueryParams =
<T extends string>(options: {keep?: T[], store?: {queryKey: string, storageKey?: string}[]} = {}) => {
  const { route, router } = useRouter()
  const queries = route.query as {[key in T]: string}
  const keepQueries: any = {}
  Object.entries(queries).forEach(([key, value]) => {
    if (options.keep && options.keep.length && options.keep.find(o => o === key)) {
      keepQueries[key] = value
    }
    if (options.store && options.store.length) {
      const queryThatShouldBeStored = options.store.find(o => o.queryKey === key)
      if (queryThatShouldBeStored) {
        sessionStorage.setItem(
          queryThatShouldBeStored.storageKey || queryThatShouldBeStored.queryKey,
          value as string
        )
      }
    }
  })

  if (Object.keys(route.query).length) {
    router.replace({ name: route.name as string, query: { ...keepQueries } })
  }
  return queries || {}
}
