


















import { defineComponent, ref } from '@vue/composition-api'
import i18n from './i18n'
import { getAanvullendeVragen } from '@/client/endpoints'
import AanvullendeVragenDetails from '@/views/AanvullendeVragen/AanvullendeVragenDetails.vue'
import AanvullendeVragenToonPersoonsgegevens from '@/views/AanvullendeVragen/AanvullendeVragenToonPersoonsgegevens.vue'
import { redirect } from '@/utils/urlMethods'
import { useRouter } from 'phoenix'
import { ValidationMessage } from '@/utils/getFunctionalError'

export default defineComponent({
  name: 'AanvullendeVragen',
  i18n,
  components: {
    AanvullendeVragenDetails,
    AanvullendeVragenToonPersoonsgegevens,
  },

  setup () {
    const toonPersoonsgegevens = ref(false)
    const toonCorrespondentievoorkeuren = ref(false)
    const isLoaded = ref(false)
    const { router } = useRouter()

    getAanvullendeVragen().then(data => {
      if (data !== null) {
        toonPersoonsgegevens.value = data.toonPersoonsgegevens
        toonCorrespondentievoorkeuren.value = data.toonCorrespondentievoorkeuren
        isLoaded.value = true
      }
    })
      .catch((err) => {
        if (err.redirectUrl) {
          redirect(err.redirectUrl)
        } else {
          router.push({
            name: 'LoginMain',
            query: {
              validationMessage: ValidationMessage.AlgemeneFout,
            },
          })
        }
      })

    return {
      toonPersoonsgegevens,
      toonCorrespondentievoorkeuren,
      isLoaded,
    }
  },
})
