import VueRouter, { RouteConfig } from 'vue-router'

export const getChildRoutes = (router: VueRouter, options: {path?: string, name?: string}): RouteConfig[] => {
  if (router.options.routes) {
    const loginRoutes = router.options.routes.find(route => {
      return route.path === options.path || route.name === options.name
    })
    if (loginRoutes && loginRoutes.children) {
      return loginRoutes.children
    }
  }
  throw new Error(`No child routes were found within: ${options.path || options.name}`)
}
