
































































































import { submitCorrespondencePreferences } from '@/client/endpoints'
import { CorrespondentieVoorkeur } from '@/client/types'
import { computed, defineComponent, ref } from '@vue/composition-api'
import i18n from './i18n'
import { useStoreAndHideQueryParams } from '@/composables/useStoreAndHideQueryParams'
import { useSubmitForm } from '@/composables/useSubmitForm'
import useCorrespondenceChoices from './useCorrespondenceChoices'
import { createEmailConfirmField, createEmailField, createTermsAndConditionsField } from './createFormFields'

export default defineComponent({
  name: 'AanvullendeVragenDetails',
  props: {
    toonCorrespondentievoorkeuren: { type: Boolean, default: false },
  },
  i18n,
  setup () {
    const correspondenceType = ref<CorrespondentieVoorkeur>(CorrespondentieVoorkeur.Digitaal)
    const correspondenceChoices = useCorrespondenceChoices()
    const preferDigitalCorrespondecne = computed(() => correspondenceType.value === CorrespondentieVoorkeur.Digitaal)
    const emailField = createEmailField({ required: preferDigitalCorrespondecne })
    const emailConfirmField = createEmailConfirmField({ emailField, required: preferDigitalCorrespondecne })
    const termsAndConditionsField = createTermsAndConditionsField()

    useStoreAndHideQueryParams({ store: [{ queryKey: 'ReturnUrl', storageKey: 'returnUrl' }] })
    const { submit, formRef, processing } = useSubmitForm(() => {
      return submitCorrespondencePreferences({
        Email: correspondenceType.value === CorrespondentieVoorkeur.Digitaal ? emailField.model : '',
        BevestigEmail: correspondenceType.value === CorrespondentieVoorkeur.Digitaal ? emailConfirmField.model : '',
        CorrespondentieVoorkeur: correspondenceType.value,
        AkkoordVoorwaarden: termsAndConditionsField.model,
      })
    })

    return {
      correspondenceChoices,
      correspondenceType,
      emailField,
      emailConfirmField,
      termsAndConditionsField,
      submit,
      formRef,
      processing,
    }
  },
})
