export default {
  messages: {
    nl: {
      pageTitle: 'Hartelijk welkom op het PGB Portaal',
      intro: {
        p1: 'Op het PGB Portaal regelt u snel, makkelijk en overzichtelijk uw pgb-administratie. ' +
        'Uw budget staat voor u klaar in uw persoonlijke omgeving.',
        p2: 'U kunt bijna aan de slag. ' +
        'Geef hieronder eerst aan hoe u geïnformeerd wilt worden en of u akkoord gaat met de algemene voorwaarden.',
      },
      correspondence: {
        title: 'Ontvang een melding als een bericht voor u klaarstaat',
        intro: 'Op het PGB Portaal ontvangt u berichten over uw administratie. ' +
        'Bijvoorbeeld als een zorgovereenkomst is goedgekeurd. ' +
        'Kies hier hoe u geïnformeerd wilt worden als een nieuw bericht klaarstaat. Per e-mail of per post.',
        mailPreference: 'Hoe wilt u berichten ontvangen?',
        receiveByEmail: 'Per e-mail',
        receiveByregularMail: 'Per post',
        email: 'E-mailadres',
        repeatEmail: 'Bevestig e-mailadres',
        hint: 'De e-mailadressen dienen gelijk te zijn.',
      },
      personalInformation: {
        title: 'Uw persoonsgegevens',
        intro: 'Om uw account aan te kunnen maken hebben we nog enkele persoonsgegevens nodig:',
        initials: 'Voorletters',
        surname: 'Achternaam',
      },
      terms: {
        title: 'Goedkeuren voorwaarden',
        description: 'Het PGB Portaal verwerkt persoonsgegevens. ' +
          'Dat is nodig om zorgovereenkomsten vast te leggen en zorgverleners uit te betalen. ' +
          'De verplichtingen en verantwoordelijkheden zijn vastgelegd in de {0}. ',
        linkLabel: 'Algemene Voorwaarden',
        checkboxLabel: 'Ja, ik ga akkoord met de voorwaarden die van toepassing zijn op het inloggen op het PGB Portaal.',
      },
      agreed: 'Akkoord',
      errorMessages: {
        notEmptyEmail: 'U heeft nog geen e-mailadres ingevuld. Vul een e-mailadres in.',
        confirmEmail: 'De e-mailadressen zijn niet gelijk. Pas één van de twee e-mailadressen aan, zodat ze gelijk zijn.',
        invalidEmail: 'Het ingevulde e-mailadres is ongeldig. Vul een geldig e-mailadres in.',
        unknownError: 'Er heeft zich een onbekende fout voorgedaan.',
        terms: 'Om verder te kunnen gaan moet u akkoord gaan met de voorwaarden.',
        initials: 'Om verder te kunnen gaan moet u uw voorletters invullen.',
        surname: 'Om verder te kunnen gaan moet u uw achternaam invullen.',
        notEmptyField: 'Dit veld mag niet leeg zijn.',
        fieldHasMoreThan32Characteres: 'In dit veld mogen maximaal 32 karakters ingevuld worden.',
        fieldHasMoreThan256Characteres: 'In dit veld mogen maximaal 256 karakters ingevuld worden.',
      },
    },
  },
}
