import Vue from 'vue'
import { phoenix } from 'phoenix'
import common from '../i18n'
import VueRouter from 'vue-router'

export default function (router?: VueRouter | undefined) {
  Vue.use(phoenix, { router })
  return {
    vuetify: phoenix.getVuetify(),
    i18n: phoenix.getVueI18n({ common }),
  }
}
