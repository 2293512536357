export default {
  messages: {
    nl: {
      pageTitle: 'Applicatiebeheer Login',
      admin: {
        cardHeader: 'Login functioneel applicatie beheerder',
        description: 'Als Functioneel applicatie beheerder kunt u hier inloggen.',
        notEnabled: 'Het is op dit moment niet mogelijk om als Functioneel applicatie beheerder in te loggen op het PGB Portaal.',
      },
      employeesLink: 'Bent u medewerker van een zorgkantoor, gemeente of de SVB?',
      budgethouderLink: 'Bent u een budgethouder, vertegenwoordiger of zorgverlener?',
      aria: {
        svb: 'Inloggen als applicatiebeheerder',
      },
    },
  },
}
