<template>
  <page-not-found
    @toPreviousPage="toPreviousPageRoute"
    @toHomepage="toHomepageRoute"
  />
</template>

<script>
import { useRouter } from 'phoenix'
import { defineComponent, onMounted } from '@vue/composition-api'
import { submitLogError } from '@/client/endpoints'

export default defineComponent({
  setup () {
    const { router } = useRouter()
    onMounted(() => {
      submitLogError({
        error: `[404: Page not found] \n Location: ${window.location}`,
        file: null,
        stack: null,
        lineNumber: null,
      })
    })
    return {
      toPreviousPageRoute: () => { router.go(-1) },
      toHomepageRoute: () => { router.push({ name: 'LoginMain' }) },
    }
  },
})
</script>
