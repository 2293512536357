import { computed, reactive, Ref, isRef } from '@vue/composition-api'
import { UnwrapRef } from '@vue/composition-api/dist/reactivity'

export interface IValidationProps {
  field?: Ref<any>
  required?: boolean | Ref<boolean>
  customValidation?: {
    validate: () => boolean
    messageId: string
  }[]
  emptyErrorMessage?: string
}
export interface IValidationResponse {
  firstErrorMessage: Readonly<Ref<string>>,
  required: boolean | Ref<boolean> | undefined
}

export const createValidation = (props: IValidationProps = {}): UnwrapRef<IValidationResponse> => {
  const message = computed(() => {
    const requiredProp = isRef(props.required) ? props.required.value : props.required
    if (requiredProp && props.field && !props.field.value) {
      return `t:errorMessages.${props.emptyErrorMessage || 'notEmpty'}`
    }
    if (props.customValidation) {
      let translation = ''
      for (const validation of props.customValidation) {
        if (!validation.validate()) {
          translation = `t:errorMessages.${validation.messageId}`
          break
        }
      }
      return translation
    }
    return ''
  })

  return reactive<IValidationResponse>({
    firstErrorMessage: message,
    required: props.required,
  })
}

export const validateForm = (formRef: Ref<any>) => {
  return Promise.resolve(formRef.value && formRef.value.validate())
}
