export const serverLoginUrl = '/login'
export const serverLogoutUrl = '/logout'

export function isServerLoginUrl (path: string): boolean {
  const value = new RegExp(`^${serverLoginUrl}(:?/)*$`, 'gm')
  return value.test(path)
}

export function isAnyLoginPage (path: string): boolean {
  const value = new RegExp(`^${serverLoginUrl}(?:$|/)`, 'gm')
  return value.test(path)
}
