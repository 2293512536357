const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000

export const cookie = {
  get (name: string) {
    name = `${name}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },
  set (name: string, value: any, expireInDays?: number) {
    let expires = ''
    if (!!expireInDays || expireInDays === 0) {
      const expireDate = new Date()
      expireDate.setTime(expireDate.getTime() + (expireInDays * MILLISECONDS_IN_DAY))
      expires = `;expires=${expireDate.toUTCString()}`
    }
    document.cookie = `${name}=${value}${expires};path=/`
  },
  delete (name: string) {
    this.set(name, '', -1)
  },
}
