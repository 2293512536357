export interface ErrorMessageResponse {path: string, id: string, href?: string}

export enum ValidationMessage {
  SvbMedewerkerOnbekend = 0,
  DigidBudgethouderHeeftVertegenwoordiger = 1,
  AlgemeneFout = 2,
  AccountGeblokkeerd = 3,
  DigidNietBekend = 4,
  DigidNietBekendZorgverlener = 5,
  DigidAnnuleren = 6,
  GeenToegangTotPortaalSessieBeeindigd = 10,
  GeenToegangTotPortaalNietDeJuisteRol = 11,
  AgbCodeOnbekend = 20,
  VecozoGebruikersnummerNietBekend = 21,
  OngeldigVecozoCertificaat = 22,
  EHerkenningGeeftGeenGegevens = 30,
  KvkNietBekendVoorVertegenwoordiger = 31,
  EherkenningAnnuleren = 32,
  KvkNietBekendVoorZorgverlener = 33,
  KvkNietBekendVoorGemeente = 34,
  ZorgverlenerMetAgbcode = 35,
  EherkenningGeenMachtiging = 36,
}

export const messages: {[key in ValidationMessage | string]: {id: string, href?: string}} = {
  [ValidationMessage.AlgemeneFout]: { id: 'algemeneFout' },
  [ValidationMessage.SvbMedewerkerOnbekend]: { id: 'svbMedewerkerOnbekend' },
  [ValidationMessage.DigidBudgethouderHeeftVertegenwoordiger]: { id: 'digidBudgethouderHeeftVertegenwoordiger' },
  [ValidationMessage.AccountGeblokkeerd]: { id: 'accountGeblokkeerd', href: 'https://www.svb.nl/nl/pgb/uw-zaken-online-regelen/inloggen-mijn-pgb' },
  [ValidationMessage.DigidNietBekend]: { id: 'digidNietBekend', href: 'https://www.svb.nl/nl/pgb/uw-zaken-online-regelen/inloggen-mijn-pgb' },
  [ValidationMessage.DigidAnnuleren]: { id: 'digidAnnuleren' },
  [ValidationMessage.GeenToegangTotPortaalSessieBeeindigd]: { id: 'geenToegangTotPortaalSessieBeeindigd' },
  [ValidationMessage.GeenToegangTotPortaalNietDeJuisteRol]: { id: 'geenToegangTotPortaalNietDeJuisteRol' },
  [ValidationMessage.AgbCodeOnbekend]: { id: 'agbCodeOnbekend', href: 'https://www.mijnpgb.nl/onderwerpen/contact' },
  [ValidationMessage.VecozoGebruikersnummerNietBekend]: { id: 'vecozoGebruikersnummerNietBekend' },
  [ValidationMessage.OngeldigVecozoCertificaat]: { id: 'ongeldigVecozoCertificaat' },
  [ValidationMessage.EHerkenningGeeftGeenGegevens]: { id: 'eHerkenningGeeftGeenGegevens' },
  [ValidationMessage.EherkenningGeenMachtiging]: { id: 'eHerkenningGeenMachtiging' },
  [ValidationMessage.KvkNietBekendVoorVertegenwoordiger]: { id: 'kvkNietBekendVoorVertegenwoordiger' },
  [ValidationMessage.KvkNietBekendVoorZorgverlener]: { id: 'kvkNietBekendVoorZorgverlener' },
  [ValidationMessage.KvkNietBekendVoorGemeente]: { id: 'kvkNietBekendVoorGemeente' },
  [ValidationMessage.ZorgverlenerMetAgbcode]: { id: 'zorgverlenerMetAgbcode' },
  [ValidationMessage.EherkenningAnnuleren]: { id: 'eHerkenningAnnuleren' },
  [ValidationMessage.DigidNietBekendZorgverlener]: { id: 'digidNietBekendZorgverlener' },
}

export function getFunctionalError (errorId: string | ValidationMessage | undefined): ErrorMessageResponse | undefined {
  if (!errorId || !messages[errorId]) {
    return undefined
  }
  return {
    path: `errorMessages.${messages[errorId].id}`,
    ...messages[errorId],
  }
}
