export interface LoginRequest {
  username: string
  password: string
  returnUrl: string | null
}
export interface LoginResponse {
  isOk: boolean
  redirectUrl?: string | null
}

export interface ProviderSettingsResponse {
  isOk: true
  providers: {
    authenticationScheme: string,
    url: string
  }[]
}

export interface LogoutResponse {
  showSignoutPrompt: boolean,
  clientName: string,
  postLogoutRedirectUri: string | null,
  signOutIFrameUrl: string | null,
  logoutId: string
}

export interface ErrorResponse {
  clientId: string
  displayMode: string | null
  error: string
  errorDescription: string
  redirectUri: string | null
  requestId: string
  responseMode: string
  uiLocales: string | null
}

export interface GetPortalResponse {
  portalUrl: string
}

export enum CorrespondentieVoorkeur {
  Onbekend = 0,
  Digitaal = 1,
  Papier = 2
}

export interface SubmitCorrespondencePreferencesRequest {
  CorrespondentieVoorkeur: CorrespondentieVoorkeur
  Email: string
  BevestigEmail: string
  AkkoordVoorwaarden: boolean
}

export interface SubmitMedewerkerDetailsRequest {
  CorrespondentieVoorkeur: CorrespondentieVoorkeur
  Voorletters: string
  Achternaam: string
  AkkoordVoorwaarden: boolean
}

export interface SubmitCorrespondencePreferencesResponse {
  isOk: boolean
  redirectUrl?: string | null
}

export interface SubmitErrorLogRequest {
  error: string | null;
  stack: string | null;
  file: string | null;
  lineNumber: string | null;
}

export interface AanvullendeVragenRequest {
  toonPersoonsgegevens: boolean;
  toonCorrespondentievoorkeuren: boolean;
}
