









































































import { submitMedewerkerDetailsRequest } from '@/client/endpoints'
import { CorrespondentieVoorkeur } from '@/client/types'
import { defineComponent } from '@vue/composition-api'
import i18n from './i18n'
import { useStoreAndHideQueryParams } from '@/composables/useStoreAndHideQueryParams'
import { useSubmitForm } from '@/composables/useSubmitForm'
import { createTermsAndConditionsField, createTextField } from './createFormFields'

export default defineComponent({
  name: 'AanvullendeVragenToonPersoonsgegevens',
  i18n,
  setup () {
    const termsAndConditionsField = createTermsAndConditionsField()

    const initials = createTextField({ required: true, length: 32, messageId: 'fieldHasMoreThan32Characteres' })
    const surname = createTextField({ required: true, length: 256, messageId: 'fieldHasMoreThan256Characteres' })
    useStoreAndHideQueryParams({ store: [{ queryKey: 'ReturnUrl', storageKey: 'returnUrl' }] })
    const { submit, formRef, processing } = useSubmitForm(() => {
      return submitMedewerkerDetailsRequest({
        CorrespondentieVoorkeur: CorrespondentieVoorkeur.Digitaal,
        AkkoordVoorwaarden: termsAndConditionsField.model,
        Achternaam: surname.model,
        Voorletters: initials.model,
      })
    })

    return {
      initials,
      surname,
      termsAndConditionsField,
      submit,
      formRef,
      processing,
    }
  },
})
