











































































































import IdentityCard from '@/components/IdentityCard.vue'
import { defineComponent } from '@vue/composition-api'
import i18n from './i18n'
import { useI18n } from 'phoenix'
import usePageCookie from '@/composables/usePageCookie'
import { useProviderSettings } from '@/composables/useProviderSettings'

export default defineComponent({
  components: { IdentityCard },
  name: 'LoginMain',
  i18n,
  setup () {
    const { t } = useI18n()
    const helpItems = [
      { label: t('help.loginHelp'), url: 'https://www.mijnpgb.nl/onderwerpen/budgethouders-en-vertegenwoordigers/inloggen-en-profiel' },
      { label: t('help.moreInfoDigid'), url: 'https://www.digid.nl/' },
      { label: t('help.moreInfoVecozo'), url: 'https://www.vecozo.nl/' },
      { label: t('help.moreInfoEherkenning'), url: 'https://www.eherkenning.nl/' },
      { label: t('help.faq'), url: 'https://www.mijnpgb.nl/onderwerpen/budgethouders-en-vertegenwoordigers/vraag-en-antwoord' },
    ]

    return {
      helpItems,
      providers: useProviderSettings().providers,
      setPageCookie: usePageCookie().set,
    }
  },
})
