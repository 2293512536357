export default {
  messages: {
    nl: {
      pageTitle: 'Test gebruiker',
      card: {
        header: 'Login testaccounts',
      },
      errorMessages: {
        notEmpty: 'Dit veld mag niet leeg zijn',
        authError: 'De combinatie van gebruikersnaam en wachtwoord is onjuist. Probeer het nog eens',
        unknownError: 'Er heeft zich een onbekende fout voorgedaan',
      },
      budgethouderLink: 'Bent u een budgethouder, vertegenwoordiger of zorgverlener?',
    },
  },
}
