import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginMain from '@/views/Login/LoginMain/LoginMain.vue'
import LoginTestUser from '@/views/Login/LoginTestUser/LoginTestUser.vue'
import LoginEmployees from '@/views/Login/LoginEmployees/LoginEmployees.vue'
import LoginAdmin from '@/views/Login/LoginAdmin/LoginAdmin.vue'
import Login from '@/views/Login/Login.vue'
import Logout from '@/views/Logout.vue'
import AanvullendeVragen from '@/views/AanvullendeVragen/AanvullendeVragen.vue'
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: Login,
    children: [
      {
        path: '/',
        name: 'LoginMain',
        meta: { title: 'Inloggen' },
        component: LoginMain,
      },
      {
        path: 'werknemers',
        name: 'LoginEmployees',
        meta: { title: 'Inloggen voor medewerkers' },
        component: LoginEmployees,
      },
      {
        path: 'test-login',
        name: 'LoginTestUser',
        component: LoginTestUser,
      },
      {
        path: 'applicatiebeheer',
        name: 'LoginAdmin',
        meta: { title: 'Inloggen voor applicatiebeheerders' },
        component: LoginAdmin,
      },
    ],
  },
  {
    path: '/aanvullendevragen',
    name: 'AanvullendeVragen',
    component: AanvullendeVragen,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/error',
    name: 'Error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error/Error.vue'),
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
