










































































































































import IdentityCard from '@/components/IdentityCard.vue'
import { defineComponent, ref } from '@vue/composition-api'
import i18n from './i18n'
import { createValidation } from '@/composables/createValidation'
import usePageCookie from '@/composables/usePageCookie'
import { useProviderSettings } from '@/composables/useProviderSettings'
import { useSubmitForm } from '@/composables/useSubmitForm'
import { loginGemnet } from '@/client/endpoints'

export default defineComponent({
  components: { IdentityCard },
  name: 'LoginEmployees',
  i18n,
  setup () {
    const username = ref<string>('')
    const password = ref<string>('')
    const usernameValidation = createValidation({ field: username, required: true, emptyErrorMessage: 'notEmpty' })
    const passwordValidation = createValidation({ field: password, required: true, emptyErrorMessage: 'notEmpty' })
    const pageCookie = usePageCookie()
    const { providers } = useProviderSettings()
    const gemnetLogin = useSubmitForm(
      () => {
        pageCookie.set()
        return loginGemnet(
          {
            username: username.value,
            password: password.value,
            returnUrl: sessionStorage.getItem('returnUrl'),
          },
          useProviderSettings().providers.value.gemnet.url.replace(/&+.*/gm, '')
        )
      }
    )

    return {
      username,
      password,
      usernameValidation,
      passwordValidation,
      providers,
      setPageCookie: pageCookie.set,
      ...gemnetLogin,
    }
  },
})

