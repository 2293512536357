import { PageCookie } from '@/composables/usePageCookie'
import { getPortalUrl } from '@/client/endpoints'
import VueRouter from 'vue-router'
import { search, redirect, pathname } from './urlMethods'
import { isAnyLoginPage, isServerLoginUrl } from './identifyPages'

export default async function (router: VueRouter): Promise<boolean> {
  const url = new URLSearchParams(search())
  const currentRoute = router.resolve({ path: pathname() }).route
  const pageCookie = new PageCookie()

  const loginUrlHasNoQueriesAndReturnUrl = isAnyLoginPage(currentRoute.path) && !url.get('ReturnUrl') && !url.get('validationMessage')
  // AanvullendeVragen page should have a returnUrl but should NOT be instantly redirected when in the same session for the reason that its not a login page.
  // So we check the sessionstorage for a returnUrl as well, that way the user won't instantly redirect back to /login when refreshing the page but only when the session expires
  const aanvullendeVragenRouteHasNoReturnUrl = currentRoute.name === 'AanvullendeVragen' && !url.get('ReturnUrl') && !sessionStorage.getItem('returnUrl')

  if (loginUrlHasNoQueriesAndReturnUrl || aanvullendeVragenRouteHasNoReturnUrl || pathname() === '/') {
    const { portalUrl } = await getPortalUrl()
    if (!isServerLoginUrl(pathname()) && loginUrlHasNoQueriesAndReturnUrl) {
      pageCookie.set(currentRoute.name as string)
    }
    redirect(portalUrl)
    // Even though the location.href is being changed, a return value should still be passed
    // Because assigning the window.location doesn't stop the js execution instantly
    return false
  }

  return true
}
