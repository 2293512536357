import { get, post } from '@/utils/httpMethods'
import {
  LoginRequest,
  LoginResponse,
  ProviderSettingsResponse,
  LogoutResponse,
  ErrorResponse,
  GetPortalResponse,
  SubmitCorrespondencePreferencesRequest,
  SubmitCorrespondencePreferencesResponse,
  SubmitErrorLogRequest,
  SubmitMedewerkerDetailsRequest,
  AanvullendeVragenRequest,
} from './types'

export function loginTestUser (args: LoginRequest) {
  return post<LoginRequest, LoginResponse>('/api/authenticate', args)
}

export function fetchProviderSettings () {
  return get<ProviderSettingsResponse>('/api/authenticate/ProviderSettings')
}

export function logout (queryParams: string | null) {
  return get<LogoutResponse>(`/api/logout/logout?logoutId=${(queryParams || '')}`)
}

export function getError (queryParams: string | null) {
  return get<ErrorResponse>(`/api/error/error?errorId=${(queryParams || '')}`)
}

export function loginGemnet (args: LoginRequest, url: string) {
  return post<LoginRequest, LoginResponse>(`/api/${url}`, args)
}

export function getPortalUrl () {
  return get<GetPortalResponse>('/api/authenticate/PortalRedirect')
}

export function submitCorrespondencePreferences (args: SubmitCorrespondencePreferencesRequest) {
  return post<SubmitCorrespondencePreferencesRequest, SubmitCorrespondencePreferencesResponse>('/api/aanvullendevragen/Aanvullende-vragen', args)
}

export function submitMedewerkerDetailsRequest (args: SubmitMedewerkerDetailsRequest) {
  return post<SubmitMedewerkerDetailsRequest, SubmitCorrespondencePreferencesResponse>('/api/aanvullendevragen/Aanvullende-vragen', args)
}
export function submitLogError (args: SubmitErrorLogRequest) {
  return post<SubmitErrorLogRequest, null>('/Logging/LogError', args)
}

export function getAanvullendeVragen () {
  return get<AanvullendeVragenRequest>('/api/aanvullendevragen/retrieve')
}
