export default {
  messages: {
    nl: {
      errorMessages: {
        general: {
          incorrectCredentials: 'Inloggen lukt niet, omdat uw gegevens niet bekend zijn binnen het PGB Portaal (PGB2.0).' +
              'Neem hierover contact op met uw verstrekker. Regelt u uw pgb-zaken in Mijn PGB van de SVB?' +
              'Ga dan naar {link}',
          maintenance: 'We voeren momenteel onderhoud uit. Daarom kunt u op dit moment niet inloggen. Probeer het over enkele uren nogmaals',
        },
        svbMedewerkerOnbekend: 'De SVB medewerker waarmee u probeert in te loggen is niet bekend.',
        digidBudgethouderHeeftVertegenwoordiger: 'Deze budgethouder heeft een vertegenwoordiger.',
        digidAnnuleren: 'Inloggen bij digid is geannuleerd',
        geenToegangTotPortaalSessieBeeindigd: 'U heeft geen toegang tot het PGB Portaal. Uw sessie is beëindigd.',
        geenToegangTotPortaalNietDeJuisteRol: 'U heeft geen toegang tot het PGB Portaal. U heeft niet de juiste rol.',
        accountGeblokkeerd: 'Inloggen lukt niet, omdat uw account voor het PGB Portaal (PGB2.0) geblokkeerd is. ' +
         'Neem hierover contact op met uw verstrekker. Regelt u uw pgb-zaken in Mijn PGB van de SVB? ' +
          'Ga dan naar {0}',
        digidNietBekend: 'Inloggen lukt niet, omdat uw gegevens niet bekend zijn binnen het PGB Portaal (PGB2.0). ' +
         'Neem hierover contact op met uw verstrekker. Regelt u uw pgb- zaken in Mijn PGB van de SVB?' +
          'Ga dan naar {0}',
        agbCodeOnbekend: 'Inloggen lukt nu niet. Uw gegevens zijn niet bekend binnen het PGB Portaal. Neem {0} op met de SVB.',
        vecozoGebruikersnummerNietBekend: 'Uw gebruikersnummer van VECOZO is niet bekend binnen het Budgethouder portaal. Uw sessie is beëindigd.',
        OngeldigVecozoCertificaat: 'Uw vecozo certificaat is niet geldig om in loggen op het PGB Portaal',
        eHerkenningGeeftGeenGegevens: 'eHerkenning geeft geen bruikbare gegevens terug.',
        eHerkenningGeenMachtiging: 'De organisatie waarvan u een machtiging hebt ontvangen, heeft u niet gemachtigd als Gemeentemedewerker.',
        eHerkenningAnnuleren: 'Inloggen bij eHerkenning is geannuleerd',
        kvkNietBekendVoorVertegenwoordiger: 'Uw Kvknummer van eHerkenning is niet bekend binnen het Budgethouder portaal. Uw sessie is beëindigd.',
        kvkNietBekendVoorZorgverlener: 'Uw Kvknummer van eHerkenning is niet bekend binnen het Budgethouder portaal. Uw sessie is beëindigd.',
        kvkNietBekendVoorGemeente: 'Uw Kvknummer van eHerkenning is niet bekend binnen het Budgethouder portaal. Uw sessie is beëindigd.',
        zorgverlenerMetAgbcode: 'U heeft een AGB-code en kan alleen inloggen via VECOZO. Uw sessie is beëindigd.',
        digidNietBekendZorgverlener: 'Uw burgerservicenummer is niet bekend binnen het PGB Portaal. Uw sessie is beëindigd',
        algemeneFout: 'Er gaat helaas iets mis bij het inloggen. Probeer het opnieuw.',
      },
      hrefLabels: {
        accountGeblokkeerd: 'Inloggen Mijn PGB',
        digidNietBekend: 'Inloggen Mijn PGB',
        agbCodeOnbekend: 'contact',
      },
    },
  },
}
