
































import { defineComponent } from '@vue/composition-api'
import { useI18n, useRouter } from 'phoenix'

export default defineComponent({
  name: 'App',
  setup () {
    const { t } = useI18n()
    const toolbarItems = [{ title: t('home'), href: 'https://www.mijnpgb.nl/', icon: '$icon-homepage' }]
    const footerLinks = [
      { url: 'https://www.mijnpgb.nl/privacy', label: t('privacy') },
      { url: 'https://www.mijnpgb.nl/cookies', label: t('cookiePolicy') },
      { url: 'https://www.mijnpgb.nl/toegankelijkheid', label: t('accessibility') },
    ]
    const { router } = useRouter()

    const signout = async () => {
      router.push({ name: 'Logout' })
    }
    return { toolbarItems, footerLinks, signout }
  },
})
