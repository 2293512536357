import { cookie } from '@/utils/cookie'

export async function get <T> (url: string): Promise<T> {
  const response = await fetch(url, {
    headers: {
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN'),
    },
    credentials: 'include',
  })
  if (response.ok) {
    return response.json()
  } else {
    return Promise.reject(await response.json())
  }
}

export async function post <T, K> (url: string, body: T): Promise<K> {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN'),
    },
    credentials: 'include',
    body: JSON.stringify(body),
  })

  if (response.ok) {
    return response.status === 204 ? response.text() : response.json()
  } else {
    return Promise.reject(await response.json())
  }
}
